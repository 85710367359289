.modal {
    .modal-dialog {
        &.bigger-modal {
            max-width: pxToRem(620px);
        }
    }

    .modal-content {
        background-color: #f4faf9;

        .form-control {
            &:focus {
                box-shadow: none !important;
            }
        }

        .password-wrap {
            position: relative;

            .show-pw {
                width: 21px;
                height: 20px;
                position: absolute;
                top: 0;
                right: 10px;
                bottom: 0;
                margin: auto;
                background-image: url('#{$relative-path-to-images}/img/icon-visibility.svg');
                background-size: auto;
                cursor: pointer;

                &.shown {
                    background-image: url('#{$relative-path-to-images}/img/icon-visibility-off.svg');
                }
            }
        }

        &.bg-white {
            background-color: white;
        }

        .modal-header {
            border-bottom: none;
            padding: pxToRem(30px) pxToRem(20px);

            .modal-title {
                font-weight: bold;
                font-size: pxToRem(20px);
                color: $color-dark;
            }

            .btn-close {
                font-weight: bold;
                color: $color-blue-darker2;

                &.custom-close {
                    background: url('#{$relative-path-to-images}/img/ico-close.svg');
                    background-size: cover;
                    width: 5px;
                    height: 5px;
                    margin-right: 0;
                    margin-top: -50px;

                    @media only screen and (max-width: 768px) {
                        opacity: 1;
                    }
                }
            }
        }

        .modal-body {
            padding: pxToRem(30px) pxToRem(20px);
            padding-top: 0;

            &.modal-added-to-cart {
                .product-wrap {
                    border: 1px solid $color-blue-light;
                    border-radius: 3px;
                    background-color: $color-alert-primary;
                    padding: pxToRem(25px) pxToRem(30px);

                    .title {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: pxToRem(25px);

                        span {
                            font-weight: bold;
                            font-size: pxToRem(20px);
                            color: $color-blue-darker;

                            @media only screen and (max-width: 992px) {
                                font-size: pxToRem(15px);
                            }
                        }

                        .icon {
                            img {
                                max-width: 18px;
                                height: auto;
                                display: block;
                                min-width: 18px;
                                margin-right: pxToRem(12px);
                            }
                        }
                    }

                    .product-info {
                        .image-wrap {
                            img {
                                max-height: 120px;
                                min-height: 120px;
                                width: 100%;
                                object-fit: cover;
                            }
                        }

                        a {
                            color: $color-blue-darker;
                            font-size: pxToRem(18px);
                            font-weight: 400;
                            text-align: left;
                            display: flex;

                            @media only screen and (max-width: 992px) {
                                font-size: pxToRem(13px);
                                margin-top: pxToRem(15px);
                                text-align: center;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                }

                .recapitulation {
                    display: flex;
                    flex-direction: row;
                    text-align: center;
                    margin-top: pxToRem(20px);
                    margin-bottom: pxToRem(35px);
                    font-weight: normal;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}

.modal-without-header {
    @media only screen and (max-width: 992px) {
        .btn-close.custom-close {
            margin-top: -15px !important;
        }
    }
}
