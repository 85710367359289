.cart-header {
    &__stepper {
        padding: pxToRem(45px) 0;

        &__step {
            cursor: pointer;

            a {
                text-decoration: none;
            }

            &__contentwrap {
                display: flex;
                flex-direction: row;
                position: relative;
                align-items: center;
                overflow: hidden;

                &::before {
                    content: '';
                    border-top: 1px solid #e8e4e4;
                    border-left: 1px solid #e8e4e4;
                    border-bottom: 1px solid #e8e4e4;
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                    display: block;
                    width: calc(100% - 27px);
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                &:after {
                    content: '';
                    border-right: 1px solid #e8e4e4;
                    border-bottom: 1px solid #e8e4e4;
                    width: 40px;
                    height: 44px;
                    transform: rotate(-45deg);
                    margin-right: 9px;
                    border-bottom-right-radius: 3px;
                    margin-top: -3px;
                }

                .number {
                    padding: pxToRem(10px) pxToRem(20px);
                    font-size: pxToRem(22px);
                    font-weight: 500;
                    background: white;
                    color: #f20001;
                    border: 1px solid #e8e4e4;
                    border-radius: 3px;
                }

                .text {
                    color: #f20001;
                    font-size: pxToRem(18px);
                    font-weight: 500;
                    margin: auto;

                    @media only screen and (max-width: 992px) {
                        font-size: pxToRem(12px);
                        font-weight: bold;
                    }
                }
            }

            .cart-header__stepper__step__contentwrap {
                &:hover {
                    &::before {
                        background: #f5f4f4;
                        z-index: 1;
                        border-color: #f20001;
                    }

                    &::after {
                        background: #f5f4f4;
                        border-color: #f20001;
                        width: 40px;
                        height: 42px;
                        margin-right: 8px;
                        border-bottom-right-radius: 3px;
                        z-index: 0;
                        margin-bottom: -2px;
                    }

                    .number {
                        background: #f20001;
                        color: white;
                        border-color: #f20001;
                        z-index: 1;
                    }

                    .text {
                        z-index: 1;
                    }
                }
            }
        }

        &__step--active {
            .cart-header__stepper__step__contentwrap {
                &::before {
                    background: #f5f4f4;
                    z-index: 1;
                    border-color: #f20001;
                }

                &::after {
                    background: #f5f4f4;
                    border-color: #f20001;
                    width: 40px;
                    height: 42px;
                    margin-right: 8px;
                    border-bottom-right-radius: 3px;
                    z-index: 0;
                    margin-bottom: -2px;
                }
            }

            .number {
                background: #f20001;
                color: white;
                border-color: #f20001;
                z-index: 1;
            }

            .text {
                z-index: 1;
            }
        }
    }
}

.cart-forms {
    &__info-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: pxToRem(35px);

        .icon {
            img {
                width: 30px;
                min-width: 30px;
                max-width: 30px;
                height: auto;
                display: block;
            }
        }

        .title {
            font-size: pxToRem(18px);
            font-weight: bold;
            color: $color-blue-darker;
            margin: pxToRem(12px) auto;
        }
    }

    #companydata,
    #deliverydata {
        display: none;
    }

    &__shippingpayment-row {
        h2 {
            padding-top: pxToRem(40px);
            margin-bottom: pxToRem(45px);

            @media only screen and (max-width: 992px) {
                font-size: pxToRem(25px);
            }

            @media only screen and (max-width: 768px) {
                font-size: pxToRem(22px);
            }
        }

        .form-check-inline {
            padding-left: pxToRem(44.875px);
            cursor: pointer;

            label {
                line-height: pxToRem(30px);
                text-decoration: underline;
            }
        }

        .form-check-input:checked {
            background-color: $color-blue-darker2;
            border-color: $color-blue-darker2;
        }

        input[type='checkbox'] {
            width: 23px !important;
            height: 23px !important;
            cursor: pointer !important;
            border-radius: 3px !important;
            outline: 2px solid $color-blue-lighter;
            border: none !important;
            margin-left: -1.425em;

            &:focus {
                box-shadow: none !important;
            }
        }

        input::-webkit-input-placeholder,
        textarea::-webkit-input-placeholder {
            color: $color-blue-darker2;
            font-weight: 300;
            opacity: 1;
        }

        input:-moz-placeholder,
        textarea:-moz-placeholder {
            color: $color-blue-darker2;
            font-weight: 300;
            opacity: 1;
        }

        .aside {
            .widget {
                padding: pxToRem(30px) pxToRem(45px);
                background-color: $color-blue-lightest;
                border: 1px solid $color-blue-light;
                border-radius: 3px;

                .title-wrap {
                    display: flex;
                    align-items: center;

                    .icon {
                        max-width: pxToRem(20px);
                        min-width: pxToRem(20px);
                        margin-right: pxToRem(5px);
                    }
                }

                hr {
                    background-color: #a5e8e8;
                    opacity: 1;
                }

                &.bottom-wave {
                    position: relative;
                    padding: 0;
                    background-color: transparent;
                    border: none;

                    .wrapper {
                        padding: pxToRem(30px) pxToRem(45px);
                        background-color: $color-blue-lightest;
                        border: 1px solid $color-blue-light;
                        border-bottom: none;
                    }

                    &::after {
                        position: relative;
                        content: '';
                        width: 100%;
                        height: auto;
                        background-image: url('#{$relative-path-to-images}/img/bg-product-aside-bottom.png');
                        background-size: contain;
                        min-height: 85px;
                        display: block;
                        background-repeat: no-repeat;

                        @media only screen and (max-width: 992px) {
                            min-height: 160px;
                        }
                    }
                }
            }
        }

        .form-check-label {
            width: 100%;
            display: flex;
            align-items: center;
            gap: pxToRem(8px);
            flex-wrap: wrap;

            .price {
                margin-left: auto;
            }
        }

        .blue-border {
            border: 1px solid $color-blue-light;
        }
    }
}

.payment-wrap {
    .bank-transfer-text {
        width: 80%;
        font-size: small;
        display: none;

        &.show {
            display: block;
        }
    }
}

.contact-information-wrap {
    .form-wrap {
        label {
            &:not(.custom-label) {
                color: $color-blue-darker2;
                font-weight: bold;
            }

            color: $color-blue-darker2;
            font-size: pxToRem(14px);
            font-weight: 500;
        }

        input[type='text'],
        input[type='password'],
        input[type='number'],
        input[type='email'],
        input[type='tel'] {
            min-height: pxToRem(50px);
            color: $color-blue-darker2;
            border: 2px solid #a5e8e8;

            &:focus {
                outline: none !important;
                box-shadow: none !important;
            }

            &::placeholder {
                color: $color-blue-darker2;
                font-weight: 300;
            }
        }
    }
}

.eshop-bottom-buttons {
    @media only screen and (max-width: 500px) {
        .buttons-wrap {
            flex-direction: column !important;

            .btn {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }

            .btn:first-child {
                margin-bottom: pxToRem(15px);
            }
        }
    }
}
